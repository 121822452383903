import React from 'react'
import { Col, Row, Tag } from 'antd'
import Icon from '@mdi/react'
import { Colors } from 'constants/Colors'
import selectIcon from 'utils/selectIcon'

const styles = {
  icon: {
    padding: 2,
    border: '1px solid',
    borderRadius: 200,
    verticalAlign: 'middle',
  },
  tag: {
    display: 'inline-flex',
    width: 'max-content',
    borderRadius: 100,
    textTransform: 'capitalize',
    padding: '0 10px 0 0',
    margin: '5px 0',
  },
}

/**
 * It returns a Tag component with a color and value prop
 * @returns A Tag component with a color and value prop.
 */
const AnimalInfo = ({ value, text, subType }) => {
  return (
    <Tag style={{ ...styles.tag, color: text }} key={`key-${subType}`}>
      <Row>
        <Col style={{ marginRight: '5px' }}>
          {subType === 'plot' || subType === 'grow_phase' ? (
            <img
              src={selectIcon(subType)}
              alt="custom icon"
              style={{
                width: '24px',
                height: '24px',
                ...styles.icon,
                borderColor: Colors.primary,
                backgroundColor: Colors.primary,
              }}
            />
          ) : (
            <Icon
              path={selectIcon(subType)}
              size={1}
              style={{
                ...styles.icon,
                borderColor: Colors.primary,
                backgroundColor: Colors.primary,
                color: '#fff',
              }}
            />
          )}
        </Col>
        <Col>{value}</Col>
      </Row>
    </Tag>
  )
}

export default AnimalInfo
