import React from 'react'
import { Marker } from '@react-google-maps/api'
import { uniqueId } from 'lodash'
import { renderToString } from 'react-dom/server'
import InfoMap from 'components/Maps/ManipulateMap/Info'
import { Colors } from 'constants/Colors'
import { pathStringToInt } from './ManipulateMap/utils'
import { pinAnimal, pinImage } from './ManipulateMap/markersIcons'

let infoWindow = null

export default function Markers({ markers }) {
  if (!markers) return null
  let list = Array.isArray(markers) ? markers : [markers]

  const markerSize = type => {
    let size = null
    switch (type) {
      case 'device': {
        size = [18, 28]
        break
      }
      case 'pinAnimal': {
        size = [26, 36]
        break
      }
      case 'pinAnimalPositionOff': {
        size = [12, 12]
        break
      }
      default: {
        size = [15, 25]
        break
      }
    }
    return size
  }

  list = pathStringToInt(list)
  const { google } = window

  /**
   * If the infoWindow is open, find the marker that matches the infoWindow's id, and update the
   * infoWindow's content with the new marker's info
   */
  const onPositionChanged = () => {
    if (infoWindow) {
      const { id } = infoWindow
      const marker = list?.filter(m => m.info).find(m => m.id === id)
      if (marker) {
        const { info, lat, lng } = marker
        infoWindow.setContent(renderToString(<InfoMap data={info} key={id} />))
        infoWindow.setPosition({
          lat: Number(lat),
          lng: Number(lng),
        })
      }
    }
  }

  /**
   * It takes a map and an id, finds the marker with that id, and if it has an info property, it loads
   * the info into the map
   * @param marker - the map object
   * @param id - the id of the marker
   */
  let mouseOverInfoWindow
  let clickInfoWindow

  const createInfoWindow = (marker, position, content, maxWidth) => {
    const infoWindow = new google.maps.InfoWindow({ position, content, maxWidth })
    infoWindow.open(marker)
    return infoWindow
  }

  const openMouseOverInfoWindow = (marker, content, maxWidth) => {
    if (!clickInfoWindow && mouseOverInfoWindow) {
      mouseOverInfoWindow.close()
    }
    if (!clickInfoWindow) {
      mouseOverInfoWindow = createInfoWindow(
        marker,
        marker.getPosition(),
        renderToString(content),
        maxWidth,
      )
    }
  }

  const closeMouseOverInfoWindow = () => {
    if (mouseOverInfoWindow && mouseOverInfoWindow.getMap() && !clickInfoWindow) {
      mouseOverInfoWindow.close()
      mouseOverInfoWindow = null // Limpar referência para permitir a recriação
    }
  }

  const handleMarkerClick = (marker, content, maxWidth, width, height, data) => mapsMouseEvent => {
    if (clickInfoWindow) {
      clickInfoWindow.close()
      clickInfoWindow = null

      openMouseOverInfoWindow(marker, content, maxWidth)
    } else {
      closeMouseOverInfoWindow()

      // Criar clickInfoWindow
      clickInfoWindow = createInfoWindow(
        marker,
        mapsMouseEvent.latLng,
        renderToString(content),
        maxWidth,
      )

      if (data.type === 'device') {
        marker.setIcon(
          pinAnimal(data.active ? data.color : Colors.gray_medium, width + 10, height + 10),
        )

        google.maps.event.addListener(clickInfoWindow, 'closeclick', () => {
          marker.setIcon(pinImage(data.type, data.active, data, width, height))
        })
      }
    }
  }

  const onLoadMarker = (marker, id) => {
    const data = list?.find(m => m.id === id && m.info)

    if (data) {
      const content = <InfoMap data={data.info} key={id} />
      const [width, height] = markerSize('device')

      switch (data.type) {
        case 'pinAnimalPositionOff':
          google.maps.event.addListener(marker, 'mouseover', () =>
            openMouseOverInfoWindow(marker, content, 350),
          )
          google.maps.event.addListener(marker, 'mouseout', closeMouseOverInfoWindow)
          break

        case 'device':
          const contentDevice = data.deviceInfo ? <InfoMap data={data.deviceInfo} key={id} /> : null

          google.maps.event.addListener(marker, 'mouseover', () =>
            openMouseOverInfoWindow(marker, contentDevice, 350),
          )
          google.maps.event.addListener(marker, 'mouseout', closeMouseOverInfoWindow)
          google.maps.event.addListener(
            marker,
            'click',
            handleMarkerClick(marker, content, 400, width, height, data),
          )
          break

        default:
          google.maps.event.addListener(
            marker,
            'click',
            handleMarkerClick(marker, content, 600, width, height, data),
          )
          break
      }
    }
  }

  const imageMarker = (type, active, marker, size) => {
    const [width, height] = size || markerSize(type)
    const getSpace = (() => {
      switch (type) {
        case 'pinAnimal': {
          return { width: width / 2, height }
        }
        default: {
          return { width: width / 2, height: height / 2 }
        }
      }
    })()

    return {
      url: pinImage(type, active, marker, width, height),
      size: new google.maps.Size(width, height),
      anchor: new google.maps.Point(getSpace.width, getSpace.height),
    }
  }

  return (
    <>
      {list.map(marker => {
        const { lat, lng, type, active, id, size } = marker
        return (
          <Marker
            onLoad={marker => onLoadMarker(marker, id)}
            onPositionChanged={() => onPositionChanged()}
            key={uniqueId(id)}
            position={{ lat, lng }}
            icon={imageMarker(type, active, marker, size)}
          />
        )
      })}
    </>
  )
}

Markers.defaultProps = {
  onLoad: () => {},
  onPositionChanged: () => {},
}
