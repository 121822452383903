export const Colors = {
  primary: '#fd4e06',
  primary_light: '#fde3cf',
  primary_light_hover: '#fd4e060d',
  primary_dark: '#001529',
  primary_hover: '#f38d63',

  info: '#0082F7',
  warning: '#FD9206',
  danger: '#fe4343',
  success: '#87d068',
  waiting: '#fed841',
  muted: '#e1e1e1',
  secondary: '#21409a',
  error: '#FF6B72',

  green: '#03e13e',
  gray_light: '#CBCBCB',
  gray_medium: '#a0a0a0',
  gray: '#484848',
  male: '#5799b7',
  female: '#b75791',

  growth: '#14cdd4',
}
